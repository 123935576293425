import {getFirestore} from "firebase/firestore"
// Import the functions you need from the SDKs you need
// Import the functions you need from the SDKs you need
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDQg65fo0lBVfMyy7xo6msH0HpJxGQ6ZbA",
  authDomain: "stringcans-4d44a.firebaseapp.com",
  projectId: "stringcans-4d44a",
  storageBucket: "stringcans-4d44a.appspot.com",
  messagingSenderId: "299498955318",
  appId: "1:299498955318:web:a8353d0758b4ffcbe3ee97"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
