import { useState, useEffect } from "react";
import "./styles.css";
import ChatWriteBox from "./pages/chatbox";

export default function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [clientId, setClientId] = useState(null);

  useEffect(() => {
    if (window.stringCansClientId) {
      setClientId(window.stringCansClientId);
    }
  }, []);

  if(!clientId) {
    return null;
  }

  return (
    <>
      {(isOpen) ? (
        <>
          <div>
            <div
              onClick={() => { setIsOpen(false) }}
              style={{
                height: "50px",
                cursor: "pointer",
                width: "50px",
                backgroundColor: "#F70000",
                borderRadius: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "fixed",
                bottom: "10px",
                right: "10px",
              }}
            >
              <svg
                width="22"
                height="22"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="#fff"
              >
                <path d="M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z" />
              </svg>
            </div>
          </div>
        </>
      ) : (
        <div
          onClick={() => setIsOpen(true)}
          className="string-cans-icon"
        >
          <div style={{ marginTop: "8px" }}>
            <svg
              width="28"
              height="24"
              viewBox="0 0 124 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.486328 0L4.98914 38.0756H28.3256L32.8304 0H0.486328Z"
                fill="#1467C3"
              />
              <path
                d="M91.1709 0L95.6757 38.0756H119.012L123.515 0H91.1709Z"
                fill="#1467C3"
              />
              <path
                d="M112.081 45.511C112.197 46.9066 112.257 48.3179 112.257 49.7449C112.257 77.4998 89.7571 100 62.0023 100C34.2474 100 11.7451 77.4998 11.7451 49.7449C11.7451 48.3218 11.804 46.9105 11.9198 45.5169L20.8508 45.6366C20.7174 46.989 20.6487 48.3591 20.6487 49.7449C20.6487 72.5828 39.1624 91.0965 62.0003 91.0965C84.8382 91.0965 103.352 72.5828 103.352 49.7449C103.352 48.3179 103.279 46.9066 103.138 45.5169L112.081 45.511Z"
                fill="#FDC101"
              />
            </svg>
          </div>
        </div> 
      )}

     {clientId &&  <ChatWriteBox  clientId={clientId} isOpen={isOpen} setIsOpen={setIsOpen}></ChatWriteBox> } 
    </>
  );
}
