import axios from 'axios';
import React, { FC, useRef } from 'react';
import toastr from "toastr"

interface TemplateProps {
    isOpen: boolean;
    onClose: any;
    cardDetails: any;
    clientId:any;
}

const UploadImageModal: FC<TemplateProps> = ({ isOpen, onClose, cardDetails, clientId }) => {
    const fileInputRef: any = useRef(null);

    const uploadImage = async (file: any) => {
        let payload = new FormData();
        const renamedFile = new File([file], cardDetails.name, {
            type: file.type,
            lastModified: file.lastModified,
        });
        payload.append("files", renamedFile)
        payload.append("client_id", clientId)
        payload.append("type", "file")
        payload.append("card_name", cardDetails?.name)
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/lead/chatbot_detail`, payload);
            onClose();
        } catch (e: any) { 
            toastr.error(e?.response?.data?.message)
            console.log(e)
        }
    }
    const handleImageClick = () => {
        // Trigger the click event of the file input when the image is clicked
        fileInputRef.current.click();
    };

    const handleFileChange = (event: any) => {
        // Handle the selected file
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            // You can perform further actions with the selected file here
            uploadImage(selectedFile)
        }
    };
    const openInNewTab = (url: string) => {
        window.open(url, "_blank");
    };
    return (
        <div className={isOpen ? "modal-backdrop" : ""}>
            <div
                className={`modal fade ${isOpen ? "show fade-in" : ""}`}
                tabIndex={-1}
                role="dialog"
                aria-labelledby="scrollableModalTitle"
                aria-hidden={!isOpen}
                style={{ display: isOpen ? "block" : "none" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0">
                        <div className="modal-header">
                            <h2 className="modal-title">{cardDetails?.name}</h2>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                onClose();
                            }}>
                                <img src={"./images/Vector.svg"} alt='icon' />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <input type="text" className="form-control" placeholder="Card Text" disabled value={cardDetails?.text} name="name" />
                            </div>
                            <div className="mb-3">
                                <div className="bg-image"onClick={handleImageClick} >
                                    <input
                                        className="form-control" name="bannerBgImage" type="file" id="bgImage"
                                        ref={fileInputRef} 
                                        onChange={(e) => handleFileChange(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}


export default UploadImageModal


