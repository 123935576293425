import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/600.css"; // Specify weight
import "@fontsource/poppins/600-italic.css"; // Specify weight and style
import "bootstrap/dist/css/bootstrap.min.css";

import App from "./App";

const rootElement = document.getElementById("chatbot-root");
const root = createRoot(rootElement);

root.render(
    <App />
);
