import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import "toastr/build/toastr.min.css";

import toastr from "toastr"
const BASE_URL = process.env.REACT_APP_BASE_URL
console.log('BASE_URL', BASE_URL)

interface ApiCallOptions {
  type: 'GET' | 'POST' | 'PATCH' | 'DELETE';
  endpoint: string;
  payload?: any;
}

const listAuthForJwt = ['auth/signin', 'auth/signin']


const apiCallService = async ({ type, endpoint, payload }: ApiCallOptions): Promise<any> => {
  try {
    let response: AxiosResponse<any, any> | null = null;
    if (type === 'GET') {
      response = await axios.get(`${BASE_URL}/${endpoint}`, {  });
    } else if (type === 'POST') {
      response = await axios.post(`${BASE_URL}/${endpoint}`, payload, {  });
    } else if (type === 'PATCH') {
      response = await axios.patch(`${BASE_URL}/${endpoint}`, payload, {  });
    } else if (type === 'DELETE') {
      response = await axios.delete(`${BASE_URL}/${endpoint}`, {  });
    }
    if (response) {
      console.log("api service", response);
      return response.data;
    } else {
      throw new Error('API call failed');
    }
  } catch (error) {
    console.log("api service", error);
  }
};

export default apiCallService;

