import { useState, useEffect, useRef, useCallback } from "react";
import "../styles.css";
import apiCallService from "../services/apiService.tsx";
import Logo from "../images/logo.svg";
import LeadIcon from "../images/lead-icon.svg";
import "font-awesome/css/font-awesome.min.css";
import axios from "axios";
import Docs from "../images/docs.svg";
import io from "socket.io-client";
import { Chance } from "chance";
import UploadImageModal from "../uploadImageModal.tsx";
import VideoCallModal from "./videoCallModal.js";
import { collection, addDoc, onSnapshot, setDoc, doc, updateDoc, getDocs, writeBatch } from "firebase/firestore";
import { db } from "../lib/firebase";

const CHAT_URL = process.env.REACT_APP_CHAT_URL
const socket = io(`${CHAT_URL}`);

export default function ChatWriteBox(props) {

  const { setIsOpen, isOpen, clientId } = props;

  const [chatArr, setChatArr] = useState([]);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const fileInputRef = useRef(null);
  const [initialLoad, setInitialLoad] = useState(false);
  const [initialChatLoad, setInitialChatLoad] = useState(false);
  const [initialSaveChat, setInitialSaveChat] = useState(false);
  const [onCard, setOnCard] = useState({});
  const [selectedOption, setSelectedOption] = useState();
  const [textMsg, setTextMsg] = useState("");
  const [liveChat, setLiveChat] = useState(false);
  const [cardDetails, setCardDetails] = useState({});
  const [currIP, setCurrIP] = useState("");
  const [openVideo, setOpenVideo] = useState(false);
  const [currLeadId, setCurrLeadId] = useState("")
  const [currEmail, setCurrEmail] = useState("")
  const [currName, setCurrName] = useState("")
  const [chatbotFormDetail, setChatbotFormDetail] = useState({});
  let savedMsgs = localStorage.getItem("allMsgs") || "[]";
  savedMsgs = JSON.parse(savedMsgs);
  const [allMsgs, setAllMsgs] = useState(savedMsgs || [])

  const handlClose = () => {
    setOpenVideo(false);
  };

  const generateString = (length, characters) => {
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  useEffect(() => {
    getChatbotForm();
    handleSubmit();
  }, []);

  useEffect(() => {
    let savedMsgs = localStorage.getItem("allMsgs") || "[]";
    savedMsgs = JSON.parse(savedMsgs) || [];
    savedMsgs.forEach(msg => {
      if(msg?.message.includes("chatting_with")){
        setLiveChat(true);
        const match = msg.message?.match(/chatting_with\s+(.+)/);
        addNewChat([
          <p className="admin-msg" style={{ textAlign: "center", width: "100%" }}>
            <span className="chat-text"

              style={{
                background: "none",
                color: "black",
                textAlign: "center"

              }}

            >{`You are now chatting with ${match?.[1] || "Support Agent."}`}</span>
          </p>,
        ]);
      }     else if(msg?.message.includes("end_chat")){
        setLiveChat(false);
      }
        else if (msg.type == "Admin") {
          addNewChat([
            <p className="admin-msg">
              <span className="chat-text">{msg?.message}</span>
              <span className="chat-time">
                <img src={Logo} alt="icon" />

                {formatCurrentDateTime()}
              </span>
            </p>
          ]);
        } else {
          addNewChat([
            <p className="user-msg">
              <span className="chat-text">{msg?.message}</span>
              <span className="chat-time">
                {formatCurrentDateTime()}
                <img src={LeadIcon} alt="icon" />
              </span>
            </p>
          ]);
        }
      })
    }, []);

  function formatCurrentDateTime() {
    const timestamp = Date.now();
    const date = new Date(timestamp);

    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      weekday: "long",
    };

    const timeString = date.toLocaleString("en-US", options);
    const [time, day] = timeString.split(", ");
    return `${time}`;
  }

  useEffect(() => {
    socket.on("call-initiated", (data) => {
      // alert('Incoming call in room: ' + data.room);

      if (window.confirm("Incoming call in room: " + data.room)) {
        // window.open('/video-call', '_blank')
        setStartCall(true);
        setOpenVideo(true)
      }

      localStorage.setItem("roomName", data.room);
    });

    return () => {
      socket.off("call-initiated");
    };
  }, []);

  const handleSubmit = async (e) => {
    e?.preventDefault();
    try {
      const chance = new Chance();
      let leadDetails = {};
      const lead_id = `LEAD_${generateString(6, '0123456789')}`;

      fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then(async (data) => {

          setIp(data.ip);
          setCurrIP(data.ip);
          const url = window.location.href;
          let response = {};

          await setDoc(doc(collection(db, "leads", clientId, data?.ip), "status"), {
            //assignedTo: "",
            createdAt: getCurrentTime(),
          }, { merge: true });

          if (data.ip)
            response = await apiCallService({
              type: "POST",
              endpoint: `lead/lead_detail/${data.ip}`,
              payload: {
                client_ids: [clientId]
              },
            });
          if (
            response?.data
          ) {
            setCurrLeadId(response?.data?.id)
            setCurrEmail(response?.data?.email)
          } else if (data.ip) {
            setCurrLeadId(lead_id)
            await axios.post(
              `${CHAT_URL}/api/chat/lead`,
              { company_id: clientId, ip: data.ip, id: lead_id },
              {}
            );
            const res = await axios.post(`${CHAT_URL}/api/chat/update/url`, {
              url: url,
              ip: data.ip,
              id: lead_id,
              lat: "",
              long: "",
              client_ids: [clientId],
            });
          }
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    let idArr = []
    if (!initialChatLoad && currIP && Object.keys(chatbotFormDetail).length > 0) {
      setInitialChatLoad(true);
      onSnapshot(collection(db, "chats", clientId, currIP), async (document) => {
        setCurrLeadId(document?.id)
        const chats = document?.docs?.map((document) => document.data()) || [];
        chats?.sort((a, b) => b.createdAt - a.createdAt);
        let message = chats?.[0]
        idArr.push(message?._id)
        if (!message?.message || message?.type == "User") {
          return;
        }
        else if (message?._id == undefined) {
          return;
        }
        else if (idArr.length > 1 && message?._id == idArr.at(-2)) {
          return;
        }
        else if (idArr.length > 2 && message?._id == idArr.at(-3)) {
          return;
        }
        else if (idArr.length > 3 && message?._id == idArr.at(-4)) {
          return;
        }
        else if (message.message?.includes("redirect_to")) {
          const match = message.message?.match(/redirect_to\s+(.+)/);
          let card = chatbotFormDetail?.cards?.find(
            (obj) => obj.name === match?.[1]
          ) ||
            chatbotFormDetail?.user_input_cards?.find(
              (obj) => obj.name === match?.[1]
            )
          if (card)
            createChatByCard(card, match)
          return
        }
        else if (message.message?.includes("chatting_with")) {
          setAllMsgs((allMsgs) => [...allMsgs, { type: message.type, message: message.message }]);
          setLiveChat(true)
          const match = message.message?.match(/chatting_with\s+(.+)/);
          addNewChat([
            <p className="admin-msg" style={{ textAlign: "center", width: "100%" }}>
              <span className="chat-text"

                style={{
                  background: "none",
                  color: "black",
                  textAlign: "center"

                }}

              >{`You are now chatting with ${match?.[1] || "Support Agent."}`}</span>
            </p>,
          ]);
        }
        else if (message.message?.includes("end_chat")) {
          localStorage.clear();
          setLiveChat(false);
          setSelectedOption("multipleOption")

          createChatByCard(
            chatbotFormDetail?.user_input_cards?.find(
              (obj) => obj.name === "Anything Else"
            )
          );
        } else if (message.type !== "User") {
          setAllMsgs((allMsgs) => [...allMsgs, { type: message.type, message: message.message }]);
          addNewChat([
            <p className="admin-msg">
              <span className="chat-text">{message?.message}</span>
              <span className="chat-time">
                <img src={Logo} alt="icon" />

                {formatCurrentDateTime()}
              </span>
            </p>,
          ]);
        }

        const collectionRef = collection(db, "chats", clientId, currIP);
        const snapshot = await getDocs(collectionRef);

        const batch = writeBatch(db);

        snapshot.docs.forEach((doc) => {
          batch.delete(doc.ref);
        });

        await batch.commit();


      });
    }

  }, [currIP, chatbotFormDetail]);

  const getChatbotForm = async () => {
    try {
      const response = await apiCallService({
        type: "POST",
        endpoint: "chatbot/frontend",
        payload: { client_id: clientId },
      });
      setChatbotFormDetail(response?.data);
      //selected options for cards: link, ""(no button), mail, phone, null(for image/file)
      //selected options for user input cards: text, multipleOption, file
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmitInput = async () => {
    try {
      if (textMsg?.length == 0) return;
      if (onCard?.name?.includes("Name (for chat)")) {
        setCurrName(textMsg)
      }
      const response = await apiCallService({
        type: "POST",
        endpoint: "lead/chatbot_detail",
        payload: {
          client_id: clientId,
          name: onCard?.name,
          input: textMsg,
          ip: currIP,
        },
      });
      addNewChat([
        <p className="user-msg">
          <span className="chat-text">{textMsg}</span>
          <span className="chat-time">
            {formatCurrentDateTime()}
            <img src={LeadIcon} alt="icon" />
          </span>
        </p>,
      ]);
      createChatByCard(
        chatbotFormDetail?.cards?.find(
          (obj) => obj._id === onCard?.redirect_to_card
        ) ||
        chatbotFormDetail?.user_input_cards?.find(
          (obj) => obj._id === onCard?.redirect_to_card
        )
      );
      setTextMsg("");
    } catch (e) {
      console.log(e);
    }
  };

  const uploadFile = async (file, name, currIP, chatbotFormDetail) => {
    try {
      let payload = new FormData();
      const renamedFile = new File([file], name?.name, {
        type: file.type,
        lastModified: file.lastModified,
      });
      payload.append("files", renamedFile);
      payload.append("client_id", clientId);
      payload.append("name", name?.name);
      payload.append("ip", currIP);
      payload.append("selected_option", "file");
      const response = await apiCallService({
        type: "POST",
        endpoint: `lead/upload_file`,
        payload: payload,
      });
      createChatByCard(
        chatbotFormDetail?.cards?.find(
          (obj) => obj._id === name?.redirect_to_card
        ) ||
        chatbotFormDetail?.user_input_cards?.find(
          (obj) => obj._id === name?.redirect_to_card
        )
      )
    } catch (e) {
      console.log(e);
    }
  };
  const handleFileChange = useCallback((event, name, currIP, chatbotFormDetail) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      uploadFile(file, name, currIP, chatbotFormDetail);
    }
  }, []);

  const handleImageClick = useCallback(() => {
    fileInputRef.current.click();
  }, []);

  useEffect(() => {

    if (chatbotFormDetail["user_input_cards"] && !initialLoad) {
      if(liveChat == false)
      createChatByCard(
        chatbotFormDetail?.cards?.find((obj) => obj.name === "Welcome")
      );else{
        return
      }
      setInitialLoad(true);
    }
  }, [chatbotFormDetail, initialLoad]);

  const openInNewTab = (url) => {
    window.open(url, "_blank");
  };

  const createChatByCard = (card) => {
    setOnCard(card);
    setSelectedOption(card?.selected_option)
    let chats = [];
    if (card?.name == "Live Chat Starts") {
      sendMessage(1)
      addNewChat([
        <p className="admin-msg" style={{ textAlign: "center", width: "100%" }}>
          <span className="chat-text"

            style={{
              background: "none",
              color: "black",
              textAlign: "center"

            }}

          >{`Please wait while we connect you to our support executive`}</span>
        </p>,
      ]);
      return;
    }
    if (card?.selected_option == "text") {
      chats.push(
        card?.text ? (
          <p className="admin-msg">
            <span className="chat-text">{card?.text}</span>
            <span className="chat-time">
              <img src={Logo} />
              {formatCurrentDateTime()}
            </span>
          </p>
        ) : (
          <></>
        )
      );
      addNewChat(chats);
    } else if (card?.selected_option == "multipleOption") {
      chats.push(
        <p className="admin-msg">
          {card?.text ? <span className="chat-text">{card?.text}</span> : <></>}
          <span className="chat-time">
            <img src={Logo} />
            {formatCurrentDateTime()}
          </span>
          {card?.multiple_options?.map((option) => {
            return (
              <button
                type="button"
                onClick={() => {
                  addNewChat([
                    <p className="user-msg">
                      <span className="chat-text">{option?.name}</span>
                      <span className="chat-time">
                        {formatCurrentDateTime()}
                        <img src={LeadIcon} alt="icon" />
                      </span>
                    </p>,
                  ]);
                  createChatByCard(
                    chatbotFormDetail?.cards?.find(
                      (obj) => obj._id === option?.redirect_to_card
                    ) ||
                    chatbotFormDetail?.user_input_cards?.find(
                      (obj) => obj._id === option?.redirect_to_card
                    )
                  );
                }}
                class="btn btn-secondary btn-sm"
                style={{
                  "--bs-btn-active-bg": "#E5E4E2",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                {option?.name}
              </button>
            );
          })}
        </p>
      );
      addNewChat(chats);
    } else if (card?.selected_option == "file") {
      setOnCard(card);
      chats.push(
        <>
          {card?.text ? (
            <p className="admin-msg">
              <span className="chat-text">{card?.text}</span>
              <span className="chat-time">
                <img src={Logo} />
                {formatCurrentDateTime()}
              </span>
            </p>
          ) : (
            <></>
          )}
          {card?.name?.length > 0 && currIP?.length > 0 ? (
            <div className="upload-container">
              <label for="file-upload" className="upload-label">
                <div className="upload-box">
                  <span className="upload-icon">
                    <img width="40px" height="40px" src={Docs} alt="" />
                  </span>
                  <span className="upload-text">
                    <input
                      className="form-control"
                      name="bannerBgImage"
                      type="file"
                      id="bgImage"
                      ref={fileInputRef}
                      onClick={() => handleImageClick()}
                      onChange={(e) => handleFileChange(e, card, currIP, chatbotFormDetail)}
                    />
                  </span>
                </div>
              </label>
            </div>
          ) : (
            <></>
          )}
        </>
      );
      addNewChat(chats);
    } else if (card?.selected_option == "link") {
      chats.push(
        card?.text ? (
          <p className="admin-msg">
            <span className="chat-text">{card?.text}</span>
            <span className="chat-time">
              <img src={Logo} />
              {formatCurrentDateTime()}
            </span>
          </p>
        ) : (
          <></>
        )
      );
      chats.push(
        <p className="admin-msg">
          <button
            type="button"
            onClick={() => {
              window.open(card?.link, "_blank");
            }}
            class="btn btn-secondary btn-sm"
            style={{
              "--bs-btn-active-bg": "#E5E4E2",
              marginRight: "10px",
              marginBottom: "10px",
            }}
          >
            {card?.button_title}
          </button>
        </p>
      );
      addNewChat(chats);
      if (card?.redirect_to_card) {
        createChatByCard(
          chatbotFormDetail?.cards?.find(
            (obj) => obj._id === card?.redirect_to_card
          ) ||
          chatbotFormDetail?.user_input_cards?.find(
            (obj) => obj._id === card?.redirect_to_card
          )
        );
      }
    } else if (card?.selected_option == "") {
      chats.push(
        card?.text ? (
          <p className="admin-msg">
            <span className="chat-text">{card?.text}</span>
            <span className="chat-time">
              <img src={Logo} />
              {formatCurrentDateTime()}
            </span>
          </p>
        ) : (
          <></>
        )
      );
      addNewChat(chats);

      if (card?.redirect_to_card) {
        chats.push(
          createChatByCard(
            chatbotFormDetail?.cards?.find(
              (obj) => obj._id === card?.redirect_to_card
            ) ||
            chatbotFormDetail?.user_input_cards?.find(
              (obj) => obj._id === card?.redirect_to_card
            )
          )
        );
      }
    } else if (card?.selected_option == "mail") {
      chats.push(
        card?.text ? (
          <p className="admin-msg">
            <span className="chat-text">{card?.text}</span>
            <span className="chat-time">
              <img src={Logo} />
              {formatCurrentDateTime()}
            </span>
          </p>
        ) : (
          <></>
        )
      );
      chats.push(
        <p className="admin-msg">
          <button
            type="button"
            onClick={() => {
              window.location.href = `mailto:${card?.button_title}`;
            }}
            class="btn btn-secondary btn-sm"
            style={{
              "--bs-btn-active-bg": "#E5E4E2",
              marginRight: "10px",
              marginBottom: "10px",
            }}
          >
            {card?.button_title}
          </button>
        </p>
      );
      addNewChat(chats);

      if (card?.redirect_to_card) {
        chats.push(
          createChatByCard(
            chatbotFormDetail?.cards?.find(
              (obj) => obj._id === card?.redirect_to_card
            ) ||
            chatbotFormDetail?.user_input_cards?.find(
              (obj) => obj._id === card?.redirect_to_card
            )
          )
        );
      }
    } else if (card?.selected_option == "phone") {
      chats.push(
        card?.text ? (
          <p className="admin-msg">
            <span className="chat-text">{card?.text}</span>
            <span className="chat-time">
              <img src={Logo} />
              {formatCurrentDateTime()}
            </span>
          </p>
        ) : (
          <></>
        )
      );
      chats.push(
        <p className="admin-msg">
          <button
            type="button"
            onClick={() => {
              window.location.href = `tel:${card?.contact_no}`;
            }}
            class="btn btn-secondary btn-sm"
            style={{
              "--bs-btn-active-bg": "#E5E4E2",
              marginRight: "10px",
              marginBottom: "10px",
            }}
          >
            {card?.button_title}
          </button>
        </p>
      );
      addNewChat(chats);

      if (card?.redirect_to_card) {
        createChatByCard(
          chatbotFormDetail?.cards?.find(
            (obj) => obj._id === card?.redirect_to_card
          ) ||
          chatbotFormDetail?.user_input_cards?.find(
            (obj) => obj._id === card?.redirect_to_card
          )
        );
      }
    } else if (card?.selected_option == null) {
      if (card?.uploaded_image_url != null) {
        chats.push(
          <p className="admin-msg">
            {!(
              card.uploaded_image_url.includes(".jpeg") ||
              card.uploaded_image_url.includes(".jpg") ||
              card.uploaded_image_url.includes(".png")
            ) ? (
              <div
                style={{
                  marginTop: "10px",
                  marginLeft: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  openInNewTab(card.uploaded_image_url);
                }}
              // onContextMenu={(e) => handleContextMenu(e, index)}
              >
                <img width="80px" height="80px" src={Docs} alt="" />
              </div>
            ) : (
              <img
                src={card?.uploaded_image_url}
                max-width="200"
                className="small-img"
                alt="file/img"
              />
            )}

            <span className="chat-time">
              <img src={Logo} />
              {formatCurrentDateTime()}
            </span>
          </p>
        );
        addNewChat(chats);

        if (card?.redirect_to_card) {
          createChatByCard(
            chatbotFormDetail?.cards?.find(
              (obj) => obj._id === card?.redirect_to_card
            ) ||
            chatbotFormDetail?.user_input_cards?.find(
              (obj) => obj._id === card?.redirect_to_card
            )
          );
        }
      } else {
        createChatByCard(
          chatbotFormDetail?.user_input_cards?.find(
            (obj) => obj.name === "Anything Else"
          )
        );
      }
    }
  };

  const addNewChat = (newChat) => {
    setChatArr((prevChatArr) => [...prevChatArr, ...newChat]);
  };

  const chatMsgRef = useRef(null);
  const scrollToBottom = () => {
    if (chatMsgRef.current) {
      chatMsgRef.current.scrollTo({
        top: chatMsgRef.current.scrollHeight,
        behavior: "smooth"
      });
    }
  }

  useEffect(() => {
    scrollToBottom();
  }, [chatArr]);

  const [ip, setIp] = useState();
  const [startCall, setStartCall] = useState(false);
  const [room, setRoom] = useState("");

  //Join the room when the component mounts
  useEffect(() => {

    //const roomId = ip; // Generate a unique room ID
    // setRoomId(roomId);
    //socket.emit("joinRoom", roomId);



    // Recurse on the next process tick, to avoid
    // exploding the stack.

    // socket.on("message", (message) => {
    //   setCurrLeadId(message?.content?.id)
    //   // setMessages((prevMessages) => [...prevMessages, message.content]);
    //   if (message.content.message.includes("redirect_to")) {
    //     const match = message.content.message.match(/redirect_to\s+(.+)/);
    //     createChatByCard(chatbotFormDetail?.cards?.find(
    //       (obj) => obj.name === match[1]
    //     ) ||
    //       chatbotFormDetail?.user_input_cards?.find(
    //         (obj) => obj.name === match[1]
    //       ))
    //   }
    //   else if (message.content.message.includes("chatting_with")) {
    //     setLiveChat(true)
    //     const match = message.content.message.match(/chatting_with\s+(.+)/);
    //     addNewChat([
    //       <p className="admin-msg" style={{ textAlign: "center", width: "100%" }}>
    //         <span className="chat-text"

    //           style={{
    //             background: "none",
    //             color: "black",
    //             textAlign: "center"

    //           }}

    //         >{`You are now chatting with ${match[1]}`}</span>
    //       </p>,
    //     ]);
    //   }
    //   else if (message.content.message.includes("end_chat")) {
    //     if (liveChat == true) {
    //       setLiveChat(false)
    //     }
    //   }
    //   else if (message.content.type === "User") {
    //     // addNewChat([
    //     //   <p className="user-msg">
    //     //     <span className="chat-text">{message.content.message}</span>
    //     //     <span className="chat-time">
    //     //       {formatCurrentDateTime()}
    //     //       <img src={LeadIcon} alt="icon" />
    //     //     </span>
    //     //   </p>,
    //     // ]);
    //   } else {
    //     addNewChat([
    //       <p className="admin-msg">
    //         <span className="chat-text">{message.content.message}</span>
    //         <span className="chat-time">
    //           {formatCurrentDateTime()}
    //           <img src={LeadIcon} alt="icon" />
    //         </span>
    //       </p>,
    //     ]);
    //   }
    //   //   createChatByCard({
    //   //     selected_option: "text",
    //   //     text: message.content.message,
    //   //   });
    // });

    // return () => {
    //   socket.off("message");
    // };
  }, [ip]);

  function getCurrentTime() {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${hours}:${minutes} ${ampm}`;
  }

  // const sendNotification = async () => {
  //   try {
  //     let currId = Math.floor(Math.random() * 1000000000).toString()
  //     await addDoc(collection(db, "notifications", clientId, "notification"), {
  //       notification: `New Chat Request from ${currName}` ,
  //       createdAt: getCurrentTime(),
  //       _id: currId
  //     });
  //     let currMsgId = Math.floor(Math.random() * 1000000000).toString()
  //     await addDoc(collection(db, "chats", clientId, currIP), {
  //       message: "Wants to connect" ,
  //       createdAt: getCurrentTime(),
  //       read: false,
  //       type: "User",
  //       _id: currMsgId
  //     });
  //   } catch (e) {
  //     console.log(e)
  //   }
  // };

  const sendMessage = async (isStart) => {
    if (isStart == 1) {
      try {
        let currId = Math.floor(Math.random() * 1000000000).toString()

        await setDoc(doc(collection(db, "notifications", clientId, "notification"), "status"), {
          notification: `New Chat Request from ${currName}`,
          createdAt: getCurrentTime(),
          _id: currId,
          type: "start_chat",
          userIp: currIP
        });
        // let currMsgId = Math.floor(Math.random() * 1000000000).toString()
        // await addDoc(collection(db, "chats", clientId, currIP), {
        //   message: "Wants to connect",
        //   createdAt: getCurrentTime(),
        //   read: false,
        //   type: "User",
        //   _id: currMsgId
        // });
      } catch (e) {
        console.log(e)
      }
    }
    else {
      try {
        let currMsgId = Math.floor(Math.random() * 1000000000).toString()
        if (textMsg.length == 0) return
        await addDoc(collection(db, "chats", clientId, currIP), {
          message: textMsg,
          createdAt: getCurrentTime(),
          read: false,
          type: "User",
          _id: currMsgId
        });
      } catch (e) {
        console.log(e)
      }
      const payload = {
        content: {
          message: textMsg,
          type: "User",
          id: currLeadId,
          read: false,
          createdAt: getCurrentTime(),
        },
        type: "User",
        client_id: clientId,
        id: currLeadId,
        email: "",
        roomId: ip,
      };
      // socket.emit("message", payload);
      console.log("allMsgs", allMsgs)
      setAllMsgs((allMsgs) => [...allMsgs, { type: "User", message: payload?.content?.message }]);

      addNewChat([
        <p className="user-msg">
          <span className="chat-text">{payload?.content?.message}</span>
          <span className="chat-time">
            {formatCurrentDateTime()}
            <img src={LeadIcon} alt="icon" />
          </span>
        </p>,
      ]);
      // setMessages((prevMessages) => [...prevMessages, payload.content]);
      setTextMsg("");
    }
  };

  useEffect(() => {
    let stringifiedMsgs = JSON.stringify(allMsgs);
    localStorage.setItem("allMsgs", stringifiedMsgs)
  }, [allMsgs]);

  const startVideoCall = async () => {
    setOpenVideo(true);
    const res = await axios.post(
      `${CHAT_URL}/api/video-call`,
      {
        room: ip,
        receiveCall: true,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    setRoom(res.data.room);
    setStartCall(true);
  };

  return (
    <>
      {isOpen ? (
        <>
          <div className="container" style={{ animationDuration: 0 }}>
            <header className="header">
              <div>
                <div>
                  <svg
                    width="25"
                    height="20"
                    viewBox="0 0 20 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.4444 5.55556C14.4444 2.48611 11.2118 0 7.22222 0C3.23264 0 0 2.48611 0 5.55556C0 6.74653 0.489583 7.84375 1.31944 8.75C0.854167 9.79861 0.0868056 10.6319 0.0763889 10.6424C0 10.7222 -0.0208333 10.8403 0.0243056 10.9444C0.0694444 11.0486 0.166667 11.1111 0.277778 11.1111C1.54861 11.1111 2.60069 10.684 3.35764 10.2431C4.47569 10.7882 5.79861 11.1111 7.22222 11.1111C11.2118 11.1111 14.4444 8.625 14.4444 5.55556ZM18.6806 13.1944C19.5104 12.2917 20 11.191 20 10C20 7.67708 18.1424 5.6875 15.5104 4.85764C15.5417 5.0868 15.5556 5.31944 15.5556 5.55556C15.5556 9.23264 11.816 12.2222 7.22222 12.2222C6.84722 12.2222 6.48264 12.1944 6.12153 12.1562C7.21528 14.1528 9.78472 15.5556 12.7778 15.5556C14.2014 15.5556 15.5243 15.2361 16.6424 14.6875C17.3993 15.1285 18.4514 15.5556 19.7222 15.5556C19.8333 15.5556 19.934 15.4896 19.9757 15.3889C20.0208 15.2882 20 15.1701 19.9236 15.0868C19.9132 15.0764 19.1458 14.2465 18.6806 13.1944Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="logo">Support Agent</div>
              </div>
              <button className="video-call-btn" onClick={startVideoCall}>
                <i class="fa-solid fa-video"></i>
              </button>
            </header>

            <div className="content">
              <div className="chat-window-wrap">
                <div className="message-content">
                  <div className="chat-msg" ref={chatMsgRef}>
                    {chatArr}
                  </div>

                  <div className="send-msg">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (onCard?.selected_option == "text") {
                          handleSubmitInput();
                        } else {
                          sendMessage(0);
                        }
                      }}
                    >
                      <input
                        hidden={(selectedOption == "text" || liveChat == true) ? false : true}
                        value={textMsg}
                        onChange={(e) => setTextMsg(e.target.value)}
                        type="text"
                        className="form-control input-margin"
                        name="msg-input"
                        id="sendMSG"
                        placeholder="Type a message..."
                      />
                      <button
                        hidden={(selectedOption == "text" || liveChat == true) ? false : true}
                        type="submit"
                        className="send-msg-btn"
                        id="sendBtn"
                      >
                        <i className="fa fa-paper-plane"></i>
                      </button>
                    </form>

                    <div className="mini-menu" style={{ display: "none" }}>
                      <ul className="list-unstyled m-0">
                        {chatbotFormDetail?.user_input_cards?.map((card) => {
                          if (card?.selected_option == "file")
                            return (
                              <li>
                                <a
                                  href="javascript:void(0)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#sendLocationModal"
                                  onClick={() => {
                                    setIsUploadOpen(true);
                                    setCardDetails(card);
                                  }}
                                >
                                  {card.name}
                                </a>
                              </li>
                            );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer className="footer">
              {" "}
              <div className="powered-by">
                Powered by{" "}
                <span style={{ marginLeft: "6px" }}>
                  {" "}
                  <svg
                    width="15"
                    height="12"
                    viewBox="0 0 124 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.486328 0L4.98914 38.0756H28.3256L32.8304 0H0.486328Z"
                      fill="#1467C3"
                    />
                    <path
                      d="M91.1709 0L95.6757 38.0756H119.012L123.515 0H91.1709Z"
                      fill="#1467C3"
                    />
                    <path
                      d="M112.081 45.511C112.197 46.9066 112.257 48.3179 112.257 49.7449C112.257 77.4998 89.7571 100 62.0023 100C34.2474 100 11.7451 77.4998 11.7451 49.7449C11.7451 48.3218 11.804 46.9105 11.9198 45.5169L20.8508 45.6366C20.7174 46.989 20.6487 48.3591 20.6487 49.7449C20.6487 72.5828 39.1624 91.0965 62.0003 91.0965C84.8382 91.0965 103.352 72.5828 103.352 49.7449C103.352 48.3179 103.279 46.9066 103.138 45.5169L112.081 45.511Z"
                      fill="#FDC101"
                    />
                  </svg>
                </span>
              </div>
            </footer>
          </div>

          <div>
          </div>
        </>
      ) : (
        <></>
      )}

      <UploadImageModal
        isOpen={isUploadOpen}
        onClose={() => setIsUploadOpen(false)}
        cardDetails={cardDetails}
        clientId={clientId}

      ></UploadImageModal>
      <VideoCallModal
        isOpen={openVideo}
        onClose={handlClose}
        startCall={startCall}
        setStartCall={setStartCall}
        setOpenVideo={setOpenVideo}
        room={room}
      />
    </>
  );
}
