import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { JitsiMeeting } from "@jitsi/react-sdk";

const VideoCallModal = ({ isOpen, onClose, room, setStartCall, setOpenVideo, startCall }) => {

  const handleModalClose = () => {
    setStartCall(false);
    setOpenVideo(false)
    onClose();
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleModalClose} center>
        {(isOpen && startCall) && (
          <JitsiMeeting
            domain="meet.jit.si"
            roomName={room}
            configOverwrite={{
              startWithAudioMuted: true,
              disableModeratorIndicator: true,
              startScreenSharing: true,
              enableEmailInStats: false,
              enableClosePage: false,
              prejoinPageEnabled: false,
              toolbarButtons: [
                "camera",
                "closedcaptions",
                "desktop",
                "download",
                "embedmeeting",
                "etherpad",
                "feedback",
                "filmstrip",
                "fullscreen",
                "hangup",
                "help",
                "highlight",
                "invite",
                "linktosalesforce",
                "livestreaming",
                "microphone",
                "noisesuppression",
                "participants-pane",
                "profile",
                "raisehand",
                "recording",
                "security",
                "select-background",
                "settings",
                "shareaudio",
                "sharedvideo",
                "shortcuts",
                "stats",
                "tileview",
                "toggle-camera",
                "videoquality",
                "whiteboard",
              ],
            }}
            interfaceConfigOverwrite={{ filmStripOnly: false }}
            userInfo={{ displayName: "User" }}
            getIFrameRef={(iframeRef) => {
              iframeRef.style.width = "800px";
              iframeRef.style.height = "600px";
            }}
            onReadyToClose={handleModalClose}
          />
        )}
      </Modal>
    </div>
  );
};

export default VideoCallModal;
